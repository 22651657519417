var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "invoice-title" }, [
                  _c(
                    "h4",
                    {
                      staticClass: "float-right font-size-16",
                      on: { click: _vm.getInvoiceDetails }
                    },
                    [
                      _vm._v(" Facture N° # "),
                      _c("b", [_vm._v(_vm._s(_vm.invoiceData.ref))]),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.invoiceData.status.rendered)
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "button-items" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.invoiceData.status.plain != "draft",
                              variant: "outline-success"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.approve($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-check" }),
                            _vm._v(" Approuver")
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                _vm.invoiceData.status.plain == "closed" ||
                                _vm.invoiceData.status.plain == "canceled",
                              variant: "outline-danger"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showInvoiceCancelModal($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-window-close" }),
                            _vm._v(" Annuler")
                          ]
                        ),
                        _vm.invoiceData.status.plain == "draft"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled:
                                    _vm.invoiceData.status.plain != "draft",
                                  variant: "outline-danger"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.showRejectionModal($event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-window-close" }),
                                _vm._v(" Bloquer")
                              ]
                            )
                          : _vm._e(),
                        _vm.invoiceData.status.plain == "blocked"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled:
                                    _vm.invoiceData.status.plain != "draft",
                                  variant: "outline-success"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.showRejectionModal($event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-window-close" }),
                                _vm._v(" Débloquer")
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                _vm.invoiceData.status.plain != "approved",
                              variant: "outline-warning"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.archive($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-archive" }),
                            _vm._v(" Clôturer")
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                _vm.invoiceData.status.plain == "closed" ||
                                _vm.invoiceData.status.plain == "draft" ||
                                _vm.invoiceData.status.plain == "canceled",
                              variant: "outline-secondary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.setDraft($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-undo" }),
                            _vm._v(" Remettre en brouillon")
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                _vm.invoiceData.status.plain == "draft" ||
                                _vm.invoiceData.status.plain == "canceled",
                              variant: "outline-info"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.printSingleDocument(
                                  "single_internal_invoice",
                                  _vm.invoiceData.uuid
                                )
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-print" }),
                            _vm._v(" Imprimer")
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("hr"),
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Infos Générales")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-3" }, [
                            _c(
                              "address",
                              [
                                _c("strong", [_vm._v("Facturé à:")]),
                                _c("br"),
                                _vm.invoiceData.contract
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceData.contract.supplier
                                              .prestname
                                          ) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceData.contract.supplier
                                              .adress1
                                          ) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceData.contract.supplier
                                              .adress2
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceData.contract.supplier
                                              .adress3
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _c("div", { staticClass: "col-3" }, [
                            _c("address", [
                              _c("strong", [_vm._v("Client:")]),
                              _c("br"),
                              _vm._v("SONATRACH "),
                              _c("br"),
                              _vm._v("Direction de production "),
                              _c("br"),
                              _vm._v("Direction régionale IRARA "),
                              _c("br"),
                              _vm._v("Hassi Messoud, Ouergla ")
                            ])
                          ]),
                          _c("div", { staticClass: "col-3 mt-3" }, [
                            _c(
                              "address",
                              [
                                _c("strong", [_vm._v("Date d'attachement:")]),
                                _vm.invoiceData.dates
                                  ? [
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          _vm.invoiceData.dates.issueDate
                                        ) + " "
                                      )
                                    ]
                                  : _vm._e(),
                                _c("br"),
                                _c("br")
                              ],
                              2
                            )
                          ]),
                          _c("div", { staticClass: "col-3 mt-3" }, [
                            _c(
                              "address",
                              [
                                _c("strong", [_vm._v("Facture:")]),
                                _c("br"),
                                _vm.invoiceData.supplierInvoice
                                  ? [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            to: {
                                              name:
                                                "suppliers_invoices.display",
                                              params: {
                                                uid:
                                                  _vm.invoiceData
                                                    .supplierInvoice.uuid
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoiceData.supplierInvoice
                                                  .ref
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  : _c("span", [_vm._v("'N/A'")]),
                                _c("br"),
                                _c("br")
                              ],
                              2
                            )
                          ]),
                          _c("div", { staticClass: "col-3" })
                        ]),
                        _c("div", { staticClass: "p-2 mt-3" }, [
                          _c("h3", { staticClass: "font-size-16" }, [
                            _vm._v("Détails des prestations")
                          ])
                        ]),
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticStyle: { width: "70px" } }, [
                                  _vm._v("No.")
                                ]),
                                _c("th", [_vm._v("Item")]),
                                _c("th", [_vm._v("Nbr ODS")]),
                                _c("th", [_vm._v("Nbr ODS Exécutés")]),
                                _c("th", [_vm._v("Contrat N°")]),
                                _c("th", { staticClass: "text-right" }, [
                                  _vm._v("Sous total")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(
                                  _vm.invoiceData.servicesGrouped,
                                  function(serviceData) {
                                    return _c(
                                      "tr",
                                      {
                                        key: serviceData.itemNo,
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(serviceData.itemNo))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(serviceData.service) +
                                              " "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.showSingleServiceDetails(
                                                    _vm.invoiceData,
                                                    serviceData
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle"
                                              }),
                                              _vm._v(" Détails")
                                            ]
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(serviceData.data.countOds)
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(serviceData.data.countOds)
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.invoiceData.contract.ref)
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  serviceData.data
                                                    .formatedWithCurrency
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                _vm.invoiceData.untaxed_amnt
                                  ? [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _c("td", {
                                            staticClass: "text-right",
                                            attrs: { colspan: "5" }
                                          }),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-4" },
                                                [_vm._v("Total HT:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.invoiceData.untaxed_amnt
                                                      .withCurrency
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "border-0 text-right",
                                              attrs: { colspan: "5" }
                                            },
                                            [_c("strong")]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "border-0 text-right"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-4" },
                                                [_vm._v("Tax:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.invoiceData.tax_amnt
                                                      .withCurrency
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm.invoiceData.hasAppliedPenalties
                                        ? _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold"
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "border-0 text-right",
                                                  attrs: { colspan: "5" }
                                                },
                                                [_c("strong")]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "border-0 text-right"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-4" },
                                                    [_vm._v("Pénalités:")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.invoiceData
                                                          .penaltyAmnt
                                                          .withCurrency
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "border-0 text-right",
                                              attrs: { colspan: "5" }
                                            },
                                            [_c("strong")]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "border-0 text-right"
                                            },
                                            [
                                              _c("h2", { staticClass: "m-0" }, [
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-4" },
                                                  [_vm._v("Total TTC:")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.invoiceData.total_amnt
                                                        .withCurrency
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Pénalités")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _vm.invoiceData.status.plain == "draft"
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary mb-2",
                                on: { click: _vm.onAddNewPenaltyClick }
                              },
                              [_vm._v(" Ajouter une Pénalité ")]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table mb-0" }, [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c("tr", [
                                _c("th", [_vm._v("ID")]),
                                _c("th", [_vm._v("Type de pénalité")]),
                                _c("th", [_vm._v("Montant")]),
                                _c("th", [_vm._v("Appliqué par")]),
                                _c("th", [_vm._v("Date")]),
                                _c("th", [_vm._v("Actions")])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.invoiceData.appliedPenalties, function(
                                penalty,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c("th", { attrs: { scope: "row" } }, [
                                    _vm._v(_vm._s(index + 1))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(penalty.type.designation))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(penalty.amount.withCurrency))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(penalty.applied_by.fullName))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(penalty.date))]),
                                  _c("td", [
                                    _vm.invoiceData.status.plain == "draft"
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onDetachPenaltyClick(
                                                  penalty
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-trash text-danger"
                                            })
                                          ]
                                        )
                                      : _c("span", [_vm._v("N/A")])
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Unités d'oeuvre")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("b", [_vm._v("Hébergement:")]),
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table mb-0" }, [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c("tr", [
                                _c("th", [_vm._v("Structure")]),
                                _c("th", [_vm._v("Prestation")]),
                                _c("th", [_vm._v("Qté")]),
                                _c("th", [_vm._v("Unité d'oeuvre")]),
                                _c("th", [_vm._v("Total")])
                              ])
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.wo.hosting, function(woItem) {
                                  return _vm._l(woItem.data, function(
                                    woStruct,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [_vm._v(_vm._s(index))]),
                                      _c("td", [
                                        _vm._v(_vm._s(woItem.roomType))
                                      ]),
                                      _c("td", [_vm._v(_vm._s(woStruct))]),
                                      _c("td", [_vm._v(_vm._s(woItem.wo))]),
                                      _c("td", [
                                        _vm._v(_vm._s(woItem.wo * woStruct))
                                      ])
                                    ])
                                  })
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: { title: "Nouvelle pénalité" },
          on: { close: _vm.onNewPenaltyModalClose },
          model: {
            value: _vm.showNewPenaltyModal,
            callback: function($$v) {
              _vm.showNewPenaltyModal = $$v
            },
            expression: "showNewPenaltyModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveNewPenalty($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "formrow-email-input" } },
                            [_vm._v("Pénalité *")]
                          ),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.newPenaltyModal.type.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.applicablePenalties,
                              placeholder: "Select Type",
                              "select-label": "",
                              "allow-empty": false
                            },
                            on: { input: _vm.onPenaltySelect },
                            scopedSlots: _vm._u([
                              {
                                key: "singleType",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.newPenaltyModal.type,
                              callback: function($$v) {
                                _vm.$set(_vm.newPenaltyModal, "type", $$v)
                              },
                              expression: "newPenaltyModal.type"
                            }
                          }),
                          _vm.submitted && _vm.$v.newPenaltyModal.type.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.newPenaltyModal.type.required
                                  ? _c("span", [_vm._v("Champ Obligatoire.")])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Méthode de calcule *")
                        ]),
                        _vm.newPenaltyModal.type
                          ? _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                disabled: "",
                                readonly: "",
                                name: "",
                                id: ""
                              },
                              domProps: {
                                value:
                                  _vm.newPenaltyModal.type.calculationMethod
                                    .name +
                                  "(" +
                                  _vm.newPenaltyModal.type.amount.formated +
                                  ")"
                              }
                            })
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Nbr de jours *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPenaltyModal.daysNo,
                              expression: "newPenaltyModal.daysNo"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", min: "1" },
                          domProps: { value: _vm.newPenaltyModal.daysNo },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newPenaltyModal,
                                  "daysNo",
                                  $event.target.value
                                )
                              },
                              _vm.onPenaltySelect
                            ]
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Montant de pénalité *")
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled: "",
                            name: "",
                            id: ""
                          },
                          domProps: { value: _vm.newPenaltyModal.penaltyAmnt }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4 text-danger" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Montant aprés pénalité *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPenaltyModal.amountAfterPenalty,
                              expression: "newPenaltyModal.amountAfterPenalty"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled: "",
                            name: "",
                            id: ""
                          },
                          domProps: {
                            value: _vm.newPenaltyModal.amountAfterPenalty
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newPenaltyModal,
                                "amountAfterPenalty",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary float-right",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Enregistrer ")
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title:
              "Détails des prestations: " + _vm.singleServiceDetails.service,
            modalClass: "fullWidthModal"
          },
          model: {
            value: _vm.showSingleServiceDetailsModal,
            callback: function($$v) {
              _vm.showSingleServiceDetailsModal = $$v
            },
            expression: "showSingleServiceDetailsModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", {}, [
                _c("div", {}, [
                  _c("h4", { staticClass: "card-title" }),
                  _c("p", { staticClass: "card-title-desc" }),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped mb-0" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("N°")]),
                          _c("th", [_vm._v("N° ODS")]),
                          _c("th", [_vm._v("Date de demande")]),
                          _c("th", [_vm._v("Date d'exécution")]),
                          _c("th", [_vm._v("Montant Globale de l'ODS")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.singleServiceDetails.odsList, function(
                          ods,
                          index
                        ) {
                          return _c("tr", { key: ods.id }, [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(_vm._s(index + 1))
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "text-primary",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showSingleOdsDetails(ods.uuid)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(ods.ref))]
                              )
                            ]),
                            _c("td", [_vm._v(_vm._s(ods.date_ODS))]),
                            _c("td", [_vm._v(_vm._s(ods.date_exec_ODS))]),
                            _c("td", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(ods.totalAmntVerified.withCurrency)
                                )
                              ])
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { modalClass: "fullWidthModal" },
          model: {
            value: _vm.showSingleOdsDetailsModal,
            callback: function($$v) {
              _vm.showSingleOdsDetailsModal = $$v
            },
            expression: "showSingleOdsDetailsModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "invoice-title" }, [
                _c("h4", { staticClass: "float-right font-size-16" }),
                _c("div", { staticClass: "mb-4" }, [
                  _c("h4", { staticClass: "font-size-16" }, [
                    _vm._v("ODS N° #" + _vm._s(_vm.singleOdsDetails.ref))
                  ])
                ])
              ]),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Contrat :")]),
                    _c("br"),
                    _c("h5", [_vm._v(_vm._s(_vm.singleOdsDetails.contract))])
                  ])
                ]),
                _c("div", { staticClass: "col-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Prestataire:")]),
                    _c("br"),
                    _c("h5", [_vm._v(_vm._s(_vm.singleOdsDetails.supplier))])
                  ])
                ]),
                _c("div", { staticClass: "col-2" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Site:")]),
                    _c("br"),
                    _c("h5", [_vm._v(_vm._s(_vm.singleOdsDetails.camp))])
                  ])
                ]),
                _c("div", { staticClass: "col-2" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Date d'exécution:")]),
                    _c("br"),
                    _c("h5", [_vm._v(_vm._s(_vm.singleOdsDetails.date))]),
                    _c("br"),
                    _c("br")
                  ])
                ]),
                _c("div", { staticClass: "col-2" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Status:")]),
                    _c("br"),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.singleOdsDetails.status)
                      }
                    }),
                    _c("br"),
                    _c("br")
                  ])
                ])
              ]),
              _c("div", { staticClass: "p-2" }, [
                _c("h3", { staticClass: "font-size-16" }, [
                  _vm._v("Prestations")
                ])
              ]),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticStyle: { width: "70px" } }, [
                        _vm._v("No.")
                      ]),
                      _c("th", [_vm._v("Item")]),
                      _c("th", { staticClass: "text-right" }, [_vm._v("Price")])
                    ])
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.singleOdsDetails.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _c("td", [_c("b", [_vm._v(_vm._s(item.name))])]),
                          _c("td", { staticClass: "text-right" }, [
                            _c("b", [
                              _vm._v(_vm._s(item.totalItemFormatedWithCurrency))
                            ])
                          ])
                        ])
                      }),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "border-0 text-right",
                            attrs: { colspan: "2" }
                          },
                          [_c("strong", [_vm._v("Total")])]
                        ),
                        _c("td", { staticClass: "border-0 text-right" }, [
                          _c("h4", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(_vm.singleOdsDetails.totalAmnt))
                          ])
                        ])
                      ])
                    ],
                    2
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "Approuver l'attachement N°: " + _vm.invoiceData.ref
          },
          model: {
            value: _vm.events.showApprovalModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showApprovalModal", $$v)
            },
            expression: "events.showApprovalModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.approveInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Fichier *")]),
                    _c(
                      "vue-dropzone",
                      {
                        ref: "myVueDropzone",
                        staticClass: "ebs-upload",
                        attrs: {
                          id: "dropzone",
                          "use-custom-slot": true,
                          options: _vm.dropzoneOptions
                        },
                        on: { "vdropzone-complete": _vm.afterComplete },
                        model: {
                          value: _vm.invoiceAttachment,
                          callback: function($$v) {
                            _vm.invoiceAttachment = $$v
                          },
                          expression: "invoiceAttachment"
                        }
                      },
                      [
                        _c("div", { staticClass: "dropzone-custom-content" }, [
                          _c("i", {
                            staticClass: "display-4 text-muted fas fa-upload"
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("h4", [
                            _vm._v(
                              "Déposez les fichiers ici ou cliquez pour télécharger."
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Commentaires")]),
                  _c("textarea", {
                    staticClass: "form-control",
                    attrs: { name: "", id: "", cols: "30", rows: "3" }
                  })
                ])
              ]),
              _c("br"),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fas fa-check" }),
                    _vm._v(" Approuver ")
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Rejeter l'attachement N°: " + _vm.invoiceData.ref },
          model: {
            value: _vm.events.showRejectionModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showRejectionModal", $$v)
            },
            expression: "events.showRejectionModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.rejectInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Raison de rejet")
                  ]),
                  _c("textarea", {
                    staticClass: "form-control",
                    attrs: {
                      name: "",
                      required: "",
                      id: "",
                      cols: "30",
                      rows: "3"
                    }
                  })
                ])
              ]),
              _c("br"),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-danger", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fas fa-window-close" }),
                    _vm._v(" Rejeter ")
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title:
              "Enregistrer un paiement pour l'attachement N°: " +
              _vm.invoiceData.ref
          },
          model: {
            value: _vm.events.showRegisterPaymentModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showRegisterPaymentModal", $$v)
            },
            expression: "events.showRegisterPaymentModal"
          }
        },
        [
          _c(
            "form",
            {
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.registerInvoicePayment($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Status *")
                      ]),
                      _c("multiselect", {
                        attrs: {
                          searchable: true,
                          "track-by": "id",
                          "select-label": "",
                          label: "name",
                          options: _vm.paymentStatusList,
                          placeholder: "Select Payment Status",
                          "allow-empty": false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "singleType",
                            fn: function(ref) {
                              var type = ref.type
                              return [_vm._v(_vm._s(type.name))]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.paymentData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.paymentData, "status", $$v)
                          },
                          expression: "paymentData.status"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Date *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentData.date,
                          expression: "paymentData.date"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "projectname",
                        type: "date",
                        placeholder: ""
                      },
                      domProps: { value: _vm.paymentData.date },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.paymentData, "date", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm.paymentData.status.id == "approved"
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("N° OV *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paymentData.ovNo,
                              expression: "paymentData.ovNo"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.paymentData.ovNo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.paymentData,
                                "ovNo",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Enregistré par")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentData.paidBy,
                          expression: "paymentData.paidBy"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "projectname",
                        type: "text",
                        readonly: "",
                        disabled: "",
                        placeholder: ""
                      },
                      domProps: { value: _vm.paymentData.paidBy },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.paymentData,
                            "paidBy",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm.paymentData.status.id == "approved"
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Attachement *")
                        ]),
                        _c(
                          "vue-dropzone",
                          {
                            ref: "myVueDropzone",
                            staticClass: "ebs-upload",
                            attrs: {
                              id: "dropzone",
                              "use-custom-slot": true,
                              options: _vm.dropzoneOptions
                            },
                            on: { "vdropzone-complete": _vm.afterComplete },
                            model: {
                              value: _vm.ovFile,
                              callback: function($$v) {
                                _vm.ovFile = $$v
                              },
                              expression: "ovFile"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "dropzone-custom-content" },
                              [
                                _c("i", {
                                  staticClass:
                                    "display-4 text-muted fas fa-upload"
                                }),
                                _vm._v(" "),
                                _c("br"),
                                _c("h4", [
                                  _vm._v(
                                    "Déposez les fichiers ici ou cliquez pour télécharger."
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Commentaires")
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentData.comments,
                          expression: "paymentData.comments"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { name: "", id: "", cols: "30", rows: "3" },
                      domProps: { value: _vm.paymentData.comments },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.paymentData,
                            "comments",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("br"),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fas fa-save" }),
                    _vm._v(" Enregistrer ")
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Annuler la facture N°: " + _vm.invoiceData.ref },
          model: {
            value: _vm.events.showInvoiceCancelModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showInvoiceCancelModal", $$v)
            },
            expression: "events.showInvoiceCancelModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.cancelInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Raison d'annulation")
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cancelModal.comment,
                        expression: "cancelModal.comment"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "",
                      required: "",
                      id: "",
                      cols: "30",
                      rows: "3"
                    },
                    domProps: { value: _vm.cancelModal.comment },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.cancelModal,
                          "comment",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger float-right",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-window-close" }),
                      _vm._v(" Annuler ")
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }