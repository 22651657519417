<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { fetchPaymentStatusList } from "@/api/common";
import Multiselect from "vue-multiselect";
import { printSingleDocumentUrl } from "@/helpers/functions";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Ordonnancement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader, Multiselect },
  import: { fetchPaymentStatusList, printSingleDocumentUrl },
  validations: {
    newPenaltyModal: {
      type: {
        required,
      },
    },
  },
  data() {
    return {
      submitted: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_BASE_URL + "system/upload/processFileUpload",
        params: {
          modalType: "",
          modelId: "",
        },
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        // autoProcessQueue : false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.doc,.docx,.xls,.xlsx",
      },
      title: "Attachement N°: ",
      showSingleOdsDetailsModal: false,
      invoiceApplicablePenaltiesBkp: [],
      invoiceData: {
        ref: "",
        status: {
          plain: "",
          rendered: "",
        },
      },
      cancelModal: {
        comment: "",
      },
      paymentStatusList: [],
      ovFile: false,
      invoiceAttachment: false,
      modelId: "",
      modelType: "",
      comments: "",
      showSingleServiceDetailsModal: false,
      singleServiceDetails: {
        odsList: [],
        service: "",
      },
      applicablePenalties: [],
      filteredApplicablePenalties: [],
      showNewPenaltyModal: false,
      newPenaltyModal: {
        type: "",
        comments: "",
        date: "",
        invoiceId: "",
        amountAfterPenalty: "",
        penaltyAmnt: "",
        daysNo: 1,
      },
      paymentData: {
        date: "",
        ovNo: "",
        status: "",
        comments: "",
        paidBy: "EBS ADMIN",
        uploadedFileData: {
          fileId: "",
        },
      },
      singleOdsDetails: {
        contract: "",
        ref: "",
        date: "",
        supplier: "",
        camp: "",
        totalAmnt: "",
        items: [],
      },
      wo: [],
      events: {
        showApprovalModal: false,
        showRejectionModal: false,
        showNewPaymentModal: false,
        showArchiveModak: false,
        showRegisterPaymentModal: false,
        showInvoiceCancelModal: false,
      },
      items: [
        {
          text: "Suivi & Gestion",
          to: { name: "monitoring_and_management" },
        },
        {
          text: "Ordonnacement",
          to: { name: "invoicing" },
        },
        {
          text: "",
          active: true,
        },
      ],
    };
  },

  mounted() {
    this.getInvoiceDetails();
  },

  methods: {
    _filterAppliedPenalties() {
      var alreadyAppliedPenalties = this.invoiceData.appliedPenalties;
      var applicablePenalties = this.applicablePenalties;
      console.log(alreadyAppliedPenalties);
      alreadyAppliedPenalties.forEach((appliedPenaltyInfos) => {
        var name = appliedPenaltyInfos.type.designation;
        applicablePenalties.forEach((applicablePenalty, index) => {
          if (name == applicablePenalty.name) {
            applicablePenalties.splice(index, 1);
          }
        });
      });
      this.applicablePenalties = applicablePenalties;
    },

    saveNewPenalty() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPenaltyModal.$touch();
      if (!this.$v.newPenaltyModal.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/invoicing/saveNewPenalty", {
            invoiceId: this.invoiceData.uuid,
            penaltyData: this.newPenaltyModal,
          })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.showNewPenaltyModal = false;
                this.newPenaltyModal.type = "";
                this.comments = "";
                this.date = "";
                // this.invoiceId = "";
                this.amountAfterPenalty = "";
                this.penaltyAmnt = "";
                this.getInvoiceDetails();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();

            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    onNewPenaltyModalClose() {
      this.newPenaltyModal.type = "";
      this.newPenaltyModal.date = "";
      this.newPenaltyModal.invoiceId = "";
      this.newPenaltyModal.amountAfterPenalty = "";
      this.newPenaltyModal.penaltyAmnt = "";
    },

    onPenaltySelect() {
      this.computePenaltyAmount();
    },

    computePenaltyAmount() {
      var penaltieType = this.newPenaltyModal.type.calculationMethod.id;
      var amntafterPenalty = 0;
      var penaltyAmnt = 0;
      var daysCount = this.newPenaltyModal.daysNo;
      switch (penaltieType) {
        case "amount":
          penaltyAmnt = this.newPenaltyModal.type.amount.plain * daysCount;
          amntafterPenalty = this.invoiceData.total_amnt.plain - penaltyAmnt;
          this.newPenaltyModal.amountAfterPenalty = new Intl.NumberFormat(
            "de-DE",
            { style: "currency", currency: "DZD" }
          ).format(amntafterPenalty);
          this.newPenaltyModal.penaltyAmnt = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "DZD",
          }).format(penaltyAmnt);
          break;

        case "percentage":
          penaltyAmnt =
            this.invoiceData.total_amnt.plain *
            (this.newPenaltyModal.type.amount.plain / 100) *
            daysCount;
          amntafterPenalty = this.invoiceData.total_amnt.plain - penaltyAmnt;
          this.newPenaltyModal.amountAfterPenalty = new Intl.NumberFormat(
            "de-DE",
            { style: "currency", currency: "DZD" }
          ).format(amntafterPenalty);
          this.newPenaltyModal.penaltyAmnt = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "DZD",
          }).format(penaltyAmnt);
          break;

        default:
          break;
      }
    },

    onAddNewPenaltyClick() {
      this._filterAppliedPenalties();
      this.showNewPenaltyModal = true;
    },
    printSingleDocument(doc, uid) {
      printSingleDocumentUrl(doc, uid);
    },
    showInvoiceCancelModal() {
      this.events.showInvoiceCancelModal = true;
    },
    async uploadFile(file, progress, error, options) {
      try {
        console.log(this.ovFile);
        const formData = new FormData();
        formData.append("file", file);
        var url = "system/upload/processFileUpload";
        const result = await this.$http.post(url, {
          file: this.ovFile.fileList[0],
        });
        progress(100); // (native fetch doesn’t support progress updates)
        return Promise.resolve({});
      } catch (err) {
        console.log(err);
        error("Impossible de transférer le fichier.");
      }
    },
    approve() {
      this.dropzoneOptions.params.attachmentType = "invoice_attachment";
      this.events.showApprovalModal = true;
    },
    approveInvoice() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/decisions/approve", {
          modelId: this.modelId,
          modelType: this.modelType,
          comments: this.comments,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.events.showApprovalModal = false;
              this.getInvoiceDetails();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    showRejectionModal() {
      this.events.showRejectionModal = true;
    },
    rejectInvoice() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/decisions/reject", {
          modelId: this.modelId,
          modelType: this.modelType,
          comments: this.comments,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.events.showRejectionModal = false;
              this.getInvoiceDetails();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    archive() {
      Swal.fire({
        title:
          "Êtes-vous sûr De Clôturer la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_close",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    cancelInvoice() {
      Swal.fire({
        title:
          "Êtes-vous sûr d'annuler la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_cancel",
              comment: this.cancelModal.comment,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.events.showInvoiceCancelModal = false;
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    setDraft() {
      Swal.fire({
        title:
          "Êtes-vous sûr De remettre la facture N°: " +
          this.invoiceData.ref +
          " en brouillon ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_draft",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    showSingleServiceDetails(invoiceData, serviceData) {
      var loader = this.$loading.show();
      this.$http
        .post("/invoicing/getSingleServiceEntriesFromInvoice", {
          invoiceId: invoiceData.uuid,
          service: serviceData.service_type,
        })

        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var ods = res.data.ods;
          switch (status) {
            case 200:
              this.singleServiceDetails.service = serviceData.service;
              this.singleServiceDetails.odsList = ods;
              this.showSingleServiceDetailsModal = true;
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    _process_ods_items(items) {
      var renderItems = [];

      return renderItems;
    },

    showSingleOdsDetails(uuid = null) {
      var loader = this.$loading.show();
      this.$http
        .post("/ods/getSingleOdsDetails", {
          odsId: uuid,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          var ods = res.data.original.ods;
          switch (status) {
            case 200:
              this.singleOdsDetails.contract = ods.contract.ref;
              this.singleOdsDetails.ref = ods.ref;
              this.singleOdsDetails.supplier = ods.service_provider.prestname;
              this.singleOdsDetails.camp = ods.lifebase.name;
              this.singleOdsDetails.totalAmnt =
                ods.totalAmntVerified.withCurrency;
              this.singleOdsDetails.status = ods.status_label;
              this.singleOdsDetails.date = ods.date_exec_ODS;
              this.singleOdsDetails.items = ods.itemsRecap;
              this.showSingleOdsDetailsModal = true;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    onDetachPenaltyClick(penalty) {
      Swal.fire({
        title:
          "Êtes-vous sûr De Supprimer la pénalité: '" +
          penalty.type.designation +
          "' de la facture N°: " +
          this.invoiceData.ref,
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/invoicing/detachPenaltyFromInvoice", {
              invoiceId: this.invoiceData.uuid,
              penaltyId: penalty.uuid,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    getInvoiceDetails() {
      var loader = this.$loading.show();
      this.$http
        .post("/invoicing/getInvoiceDetails", {
          invoiceId: this.$route.params.uid,
        })

        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var invoice = res.data.invoice;
          switch (status) {
            case 200:
              this.invoiceData = invoice;
              this.title = "";
              this.title = this.title + "#" + invoice.ref;
              this.items[this.items.length - 1].text = this.title;
              this.applicablePenalties = invoice.applicablePenalties;
              this.invoiceApplicablePenaltiesBkp = invoice.applicablePenalties;
              this.modelId = invoice.id;
              this.modelType = invoice.modelType;
              // set data to DZ params
              this.dropzoneOptions.params.modalType = invoice.modelType;

              this.dropzoneOptions.params.modalId = invoice.id;
              this.wo = invoice.internalWo;
              //   console.log(this.invoiceData.);
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.paymentData.uploadedFileData.fileId = res.original.uploadedFile.uuid;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 class="float-right font-size-16" @click="getInvoiceDetails">
                Facture N° # <b>{{ invoiceData.ref }}</b>
                <span v-html="invoiceData.status.rendered"></span>
              </h4>
              <div class="mb-4">
                <div class="button-items">
                  <b-button
                    @click.prevent="approve"
                    :disabled="invoiceData.status.plain != 'draft'"
                    variant="outline-success"
                  >
                    <i class="fas fa-check"></i> Approuver</b-button
                  >
                  <b-button
                    @click.prevent="showInvoiceCancelModal"
                    :disabled="
                      invoiceData.status.plain == 'closed' ||
                        invoiceData.status.plain == 'canceled'
                    "
                    variant="outline-danger"
                  >
                    <i class="fas fa-window-close"></i> Annuler</b-button
                  >
                  <b-button
                    @click.prevent="showRejectionModal"
                    v-if="invoiceData.status.plain == 'draft'"
                    :disabled="invoiceData.status.plain != 'draft'"
                    variant="outline-danger"
                  >
                    <i class="fas fa-window-close"></i> Bloquer</b-button
                  >

                  <b-button
                    @click.prevent="showRejectionModal"
                    v-if="invoiceData.status.plain == 'blocked'"
                    :disabled="invoiceData.status.plain != 'draft'"
                    variant="outline-success"
                  >
                    <i class="fas fa-window-close"></i> Débloquer</b-button
                  >
                  <b-button
                    @click.prevent="archive"
                    :disabled="invoiceData.status.plain != 'approved'"
                    variant="outline-warning"
                  >
                    <i class="fas fa-archive"></i> Clôturer</b-button
                  >
                  <b-button
                    @click.prevent="setDraft"
                    :disabled="
                      invoiceData.status.plain == 'closed' ||
                        invoiceData.status.plain == 'draft' ||
                        invoiceData.status.plain == 'canceled'
                    "
                    variant="outline-secondary"
                  >
                    <i class="fas fa-undo"></i> Remettre en brouillon</b-button
                  >

                  <b-button
                    @click.prevent="
                      printSingleDocument(
                        'single_internal_invoice',
                        invoiceData.uuid
                      )
                    "
                    :disabled="
                      invoiceData.status.plain == 'draft' ||
                        invoiceData.status.plain == 'canceled'
                    "
                    variant="outline-info"
                  >
                    <i class="fas fa-print"></i> Imprimer</b-button
                  >
                </div>
              </div>
            </div>
            <hr />

            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Infos Générales</span>
                </template>
                <div class="row">
                  <div class="col-3">
                    <address>
                      <strong>Facturé à:</strong><br />
                      <template v-if="invoiceData.contract">
                        {{ invoiceData.contract.supplier.prestname }} <br />
                        {{ invoiceData.contract.supplier.adress1 }} <br />
                        {{ invoiceData.contract.supplier.adress2 }}<br />
                        {{ invoiceData.contract.supplier.adress3 }}
                      </template>
                    </address>
                  </div>
                  <div class="col-3">
                    <address>
                      <strong>Client:</strong>
                      <br />SONATRACH <br />Direction de production
                      <br />Direction régionale IRARA <br />Hassi Messoud,
                      Ouergla
                    </address>
                  </div>
                  <div class="col-3 mt-3">
                    <address>
                      <strong>Date d'attachement:</strong>
                      <template v-if="invoiceData.dates">
                        <br />{{ invoiceData.dates.issueDate }}
                      </template>
                      <br />
                      <br />
                    </address>
                  </div>
                  <div class="col-3 mt-3">
                    <address>
                      <strong>Facture:</strong>
                      <br />
                      <template v-if="invoiceData.supplierInvoice">
                        <router-link
                          target="_blank"
                          :to="{
                            name: 'suppliers_invoices.display',
                            params: { uid: invoiceData.supplierInvoice.uuid },
                          }"
                        >
                          {{ invoiceData.supplierInvoice.ref }}
                        </router-link>
                      </template>
                      <span v-else>'N/A'</span>
                      <br />
                      <br />
                    </address>
                  </div>
                  <div class="col-3"></div>
                </div>
                <div class="p-2 mt-3">
                  <h3 class="font-size-16">Détails des prestations</h3>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th style="width: 70px;">No.</th>
                        <th>Item</th>
                        <th>Nbr ODS</th>
                        <th>Nbr ODS Exécutés</th>
                        <th>Contrat N°</th>
                        <th class="text-right">Sous total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style="font-weight: bold;"
                        v-for="serviceData in invoiceData.servicesGrouped"
                        v-bind:key="serviceData.itemNo"
                      >
                        <td>{{ serviceData.itemNo }}</td>
                        <td>
                          {{ serviceData.service }}
                          <a
                            href="javascript:void(0)"
                            @click.prevent="
                              showSingleServiceDetails(invoiceData, serviceData)
                            "
                            ><i class="fas fa-info-circle"></i> Détails</a
                          >
                        </td>
                        <td>{{ serviceData.data.countOds }}</td>
                        <td>{{ serviceData.data.countOds }}</td>
                        <td>{{ invoiceData.contract.ref }}</td>
                        <td class="text-right">
                          {{ serviceData.data.formatedWithCurrency }}
                        </td>
                      </tr>

                      <!--  TOTALS -->
                      <template v-if="invoiceData.untaxed_amnt">
                        <tr style="font-weight: bold;">
                          <td colspan="5" class="text-right"></td>
                          <td class="text-right">
                            <span class="mr-4">Total HT:</span>
                            {{ invoiceData.untaxed_amnt.withCurrency }}
                          </td>
                        </tr>
                        <tr style="font-weight: bold;">
                          <td colspan="5" class="border-0 text-right">
                            <strong></strong>
                          </td>
                          <td class="border-0 text-right">
                            <span class="mr-4">Tax:</span>
                            {{ invoiceData.tax_amnt.withCurrency }}
                          </td>
                        </tr>
                        <tr
                          v-if="invoiceData.hasAppliedPenalties"
                          style="font-weight: bold;"
                        >
                          <td colspan="5" class="border-0 text-right">
                            <strong></strong>
                          </td>
                          <td class="border-0 text-right">
                            <span class="mr-4">Pénalités:</span>
                            {{ invoiceData.penaltyAmnt.withCurrency }}
                          </td>
                        </tr>
                        <tr style="font-weight: bold;">
                          <td colspan="5" class="border-0 text-right">
                            <strong></strong>
                          </td>
                          <td class="border-0 text-right">
                            <h2 class="m-0">
                              <span class="mr-4">Total TTC:</span>
                              {{ invoiceData.total_amnt.withCurrency }}
                            </h2>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Pénalités</span>
                </template>
                <button
                  class="btn btn-primary mb-2"
                  v-if="invoiceData.status.plain == 'draft'"
                  @click="onAddNewPenaltyClick"
                >
                  Ajouter une Pénalité
                </button>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Type de pénalité</th>
                        <th>Montant</th>
                        <th>Appliqué par</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(penalty, index) in invoiceData.appliedPenalties"
                        v-bind:key="index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ penalty.type.designation }}</td>
                        <td>{{ penalty.amount.withCurrency }}</td>
                        <td>{{ penalty.applied_by.fullName }}</td>
                        <td>{{ penalty.date }}</td>
                        <td>
                          <a
                            v-if="invoiceData.status.plain == 'draft'"
                            href="javascript:void(0)"
                            @click="onDetachPenaltyClick(penalty)"
                            ><i class="fas fa-trash text-danger"></i
                          ></a>
                          <span v-else>N/A</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Unités d'oeuvre</span>
                </template>
                <b>Hébergement:</b>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th>Structure</th>
                        <th>Prestation</th>
                        <th>Qté</th>
                        <th>Unité d'oeuvre</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="woItem in wo.hosting">
                        <tr
                          v-for="(woStruct, index) in woItem.data"
                          v-bind:key="index"
                        >
                          <td>{{ index }}</td>
                          <td>{{ woItem.roomType }}</td>
                          <td>{{ woStruct }}</td>
                          <td>{{ woItem.wo }}</td>
                          <td>{{ woItem.wo * woStruct }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- APPLY PENALTY -->
    <Modal
      @close="onNewPenaltyModalClose"
      v-model="showNewPenaltyModal"
      title="Nouvelle pénalité"
    >
      <form @submit.prevent="saveNewPenalty">
        <div class="row">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Pénalité *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newPenaltyModal.type"
                    track-by="id"
                    label="name"
                    :options="applicablePenalties"
                    placeholder="Select Type"
                    :select-label="''"
                    :class="{
                      'is-invalid': submitted && $v.newPenaltyModal.type.$error,
                    }"
                    @input="onPenaltySelect"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newPenaltyModal.type.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newPenaltyModal.type.required"
                      >Champ Obligatoire.</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Méthode de calcule *</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    readonly
                    v-if="newPenaltyModal.type"
                    :value="
                      newPenaltyModal.type.calculationMethod.name +
                        '(' +
                        newPenaltyModal.type.amount.formated +
                        ')'
                    "
                    name=""
                    id=""
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Nbr de jours *</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    @input="onPenaltySelect"
                    v-model="newPenaltyModal.daysNo"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Montant de pénalité *</label>
                  <input
                    type="text"
                    disabled
                    :value="newPenaltyModal.penaltyAmnt"
                    class="form-control"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div class="col-md-4 text-danger">
                <div class="form-group">
                  <label for="formrow-email-input"
                    >Montant aprés pénalité *</label
                  >
                  <input
                    type="text"
                    v-model="newPenaltyModal.amountAfterPenalty"
                    disabled
                    class="form-control"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
    <!-- Invoice service details modal -->
    <Modal
      v-model="showSingleServiceDetailsModal"
      :title="`Détails des prestations: ${singleServiceDetails.service}`"
      modalClass="fullWidthModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="">
              <h4 class="card-title"></h4>
              <p class="card-title-desc"></p>

              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>N°</th>
                      <th>N° ODS</th>
                      <th>Date de demande</th>
                      <th>Date d'exécution</th>
                      <th>Montant Globale de l'ODS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ods, index) in singleServiceDetails.odsList"
                      v-bind:key="ods.id"
                    >
                      <th scope="row">{{ index + 1 }}</th>
                      <td>
                        <span
                          style="cursor:pointer;"
                          class="text-primary"
                          @click="showSingleOdsDetails(ods.uuid)"
                          >{{ ods.ref }}</span
                        >
                      </td>
                      <td>{{ ods.date_ODS }}</td>
                      <td>{{ ods.date_exec_ODS }}</td>
                      <td>
                        <b>{{ ods.totalAmntVerified.withCurrency }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- single ODS details -->
    <Modal v-model="showSingleOdsDetailsModal" modalClass="fullWidthModal">
      <div class="row">
        <div class="col-lg-12">
          <div class="invoice-title">
            <h4 class="float-right font-size-16"></h4>
            <div class="mb-4">
              <h4 class="font-size-16">ODS N° #{{ singleOdsDetails.ref }}</h4>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-3">
              <address>
                <strong>Contrat :</strong>
                <br />
                <h5>{{ singleOdsDetails.contract }}</h5>
              </address>
            </div>
            <div class="col-3">
              <address>
                <strong>Prestataire:</strong>
                <br />
                <h5>{{ singleOdsDetails.supplier }}</h5>
              </address>
            </div>
            <div class="col-2">
              <address>
                <strong>Site:</strong>
                <br />
                <h5>{{ singleOdsDetails.camp }}</h5>
              </address>
            </div>
            <div class="col-2">
              <address>
                <strong>Date d'exécution:</strong>
                <br />
                <h5>{{ singleOdsDetails.date }}</h5>
                <br />
                <br />
              </address>
            </div>
            <div class="col-2">
              <address>
                <strong>Status:</strong>
                <br /><span v-html="singleOdsDetails.status"></span>
                <br />
                <br />
              </address>
            </div>
          </div>

          <div class="p-2">
            <h3 class="font-size-16">Prestations</h3>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 70px;">No.</th>
                  <th>Item</th>
                  <th class="text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in singleOdsDetails.items"
                  v-bind:key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <b>{{ item.name }}</b>
                  </td>
                  <td class="text-right">
                    <b>{{ item.totalItemFormatedWithCurrency }}</b>
                  </td>
                </tr>

                <tr>
                  <td colspan="2" class="border-0 text-right">
                    <strong>Total</strong>
                  </td>
                  <td class="border-0 text-right">
                    <h4 class="m-0">{{ singleOdsDetails.totalAmnt }}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
    <!-- 1- APPROBATION MODAL -->
    <Modal
      v-model="events.showApprovalModal"
      :title="`Approuver l'attachement N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="approveInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Fichier *</label>
            <vue-dropzone
              id="dropzone"
              v-model="invoiceAttachment"
              class="ebs-upload"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-complete="afterComplete"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted fas fa-upload"></i> <br />
                <h4>Déposez les fichiers ici ou cliquez pour télécharger.</h4>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-12">
            <label for="">Commentaires</label>
            <textarea
              name=""
              class="form-control"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div>
          <button class="btn btn-primary" type="submit">
            <i class="fas fa-check"></i>
            Approuver
          </button>
        </div>
      </form>
    </Modal>

    <!-- 2- Rejection MODAL -->
    <Modal
      v-model="events.showRejectionModal"
      :title="`Rejeter l'attachement N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="rejectInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Raison de rejet</label>
            <textarea
              name=""
              required
              class="form-control"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div>
          <button class="btn btn-danger" type="submit">
            <i class="fas fa-window-close"></i>
            Rejeter
          </button>
        </div>
      </form>
    </Modal>
    <!-- 3- Register Payment MODAL -->
    <Modal
      v-model="events.showRegisterPaymentModal"
      :title="
        `Enregistrer un paiement pour l'attachement N°: ${invoiceData.ref}`
      "
    >
      <form
        @submit.prevent="registerInvoicePayment"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Status *</label>
              <multiselect
                :searchable="true"
                v-model="paymentData.status"
                track-by="id"
                :select-label="''"
                label="name"
                :options="paymentStatusList"
                placeholder="Select Payment Status"
                :allow-empty="false"
              >
                <template slot="singleType" slot-scope="{ type }">{{
                  type.name
                }}</template>
              </multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Date *</label>
              <input
                id="projectname"
                type="date"
                v-model="paymentData.date"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>

          <div class="col-md-4" v-if="paymentData.status.id == 'approved'">
            <div class="form-group">
              <label for="formrow-email-input">N° OV *</label>
              <input
                id="projectname"
                type="text"
                v-model="paymentData.ovNo"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Enregistré par</label>
              <input
                id="projectname"
                type="text"
                readonly
                v-model="paymentData.paidBy"
                disabled
                class="form-control"
                placeholder=""
              />
            </div>
          </div>

          <div class="col-md-12" v-if="paymentData.status.id == 'approved'">
            <label for="formrow-email-input">Attachement *</label>
            <vue-dropzone
              id="dropzone"
              v-model="ovFile"
              class="ebs-upload"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-complete="afterComplete"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted fas fa-upload"></i> <br />
                <h4>Déposez les fichiers ici ou cliquez pour télécharger.</h4>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="formrow-email-input">Commentaires</label>
              <textarea
                name=""
                v-model="paymentData.comments"
                class="form-control"
                id=""
                cols="30"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <br />

        <div>
          <button class="btn btn-primary" type="submit">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>

    <Modal
      v-model="events.showInvoiceCancelModal"
      :title="`Annuler la facture N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="cancelInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Raison d'annulation</label>
            <textarea
              name=""
              required
              class="form-control"
              v-model="cancelModal.comment"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-danger float-right" type="submit">
              <i class="fas fa-window-close"></i>
              Annuler
            </button>
          </div>
        </div>
      </form>
    </Modal>

    <!-- 4- APPROBATION MODAL -->
  </Layout>
</template>
